.commentWrapper, .responseWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.commentWrapper {
  margin: 5px;
}

.comment {
  background-color: #374785;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  max-width: 60%;
  text-align: left;
}

.responseWrapper {
  width: 60%;
  justify-content: flex-start;
  margin: 0 5px;
}

.responseTags, .responseScores {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #24305e;

}
.responseTags {
  padding: 2px 0 2px 5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.responseScores {
  width: 100%;
  padding: 2px 5px 2px 0;
  align-items: flex-start;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.scoresWrapper {

}

.tag, .score {
  box-sizing: border-box;
  height: 20px;
  margin: 5px 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

}

.tag {
  display: flex;
  font-size: 14px;
  padding: 0 10px;
  background-color: #f76c6c;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;
  flex-direction: row;
  align-items: center;
}

.score {
  padding: 0;
  background-color: #f76c6c;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}