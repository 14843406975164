.App {
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #a8d0e6;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  background-color: #a8d0e6;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #24305e;
  font-weight: bolder;
  font-size: 20px;
  font-family: "Impact", sans-serif;
}

.headerText {
  margin-right: 10px;
  user-select: none;
}

#gradientOpacity {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to top, rgba(168, 208, 230,0), rgba(168, 208, 230,1));
}

#modal {
  background-color: #a8d0e6;
  color: #24305e;
  border: none;
}

.modalHeader {
  margin: 0 20px;
  text-align: center;
  font-weight: 800;
  font-size: 40px;
}

.modalText {
  margin: 20px;
  text-align: center
}